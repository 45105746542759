import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Toast, Row, Col, Dropdown,DropdownButton,Alert } from 'react-bootstrap'
import { cap,pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import bunga6 from '../assets/img/bunga6.png'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import patern from '../assets/img/kristi/3773.svg'
import AOS from 'aos';
import { gambar } from '../params'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import getData from '../params/getdata'
import post from '../params/post'
import cuci from '../assets/img/selly/cuci.svg'


let cmain = '#fce7d2'
let mainfont = '#AD3243'
let csub = " #E3B62D"
let black = 'rgb(38,38,38)'
let id = 'shinta-ridwan'
let inisial_co = 'Ridwan'
let inisial_ce = 'Shinta'
let lengkap_co = 'Ridwan Ramadhan'
let lengkap_ce = 'Shinta Rizqi Amalia'
let bapak_co = 'Bpk. Wawan Gamal Irwana'
let ibu_co = 'Ibu Arismita'
let bapak_ce = " Bpk. Ronny Syahrani BAS (alm)"
let ibu_ce = "Ibu Juli Juwanti"
let ig_co = "Ridwancimol"
let ig_ce = "Shintarizqi"

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2,
            showgift: false,
            show: false,
            show1: false,
            show2: false
        }
    }
    dropdown = () => {
        let { friend } = this.state
        let item = []
        for (let index = 1; index < 3; index++) {
            item[index] = (<Dropdown.Item onClick={() => {
                this.setState({ friend: index })
            }}>{index}</Dropdown.Item>)
        }
        item.push(<Dropdown.Item onClick={() => {
            this.setState({ friend: 'Tentative' })
        }}>Tentative</Dropdown.Item>)
        item.push(<Dropdown.Item onClick={() => {
            this.setState({ friend: 'Not Attend' })
        }}>Not Attend</Dropdown.Item>)
        return (
            <>
                <Item>
                    <p style={{ fontSize: '16px', color: '#86835d' }} className="mb-0 w-100 text-center">
                        How many people you coming with?
          </p>
                    <DropdownButton id="dropdown-basic-button" title={friend == null ? '1' : friend}>
                        {
                            item
                        }
                    </DropdownButton>
                </Item>
            </>
        )
    }
    async componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date("03/07/2021").getTime();
        // Update the count down every 1 second
        var x = setInterval(() => {
            // Get today's date and time
            var now = new Date().getTime();
            // Find the distance between now and the count down date
            var distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })
        }, 1000);
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment = await getData(`kepada:"${id}"`)
            this.setState({ comment: comment })
        } catch (error) {
            console.log(error)
        }
    }
    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id,"music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.friend}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showgift, comment, show1, show2 } = this.state
        let slide = [
            "1205.jpg",
"1206.jpg",
"1207.jpg",
"1210.jpg",
"1211.jpg",
"1218.jpg",
        ]
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id,v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
        })
        let query = this.useQuery().get('u');
        let time = this.useQuery().get('time');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: cmain,
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${pw(id,"modal.jpg")}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={pw(id,"logobaru.png")} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth:<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button roboto-slab text-center btn ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                  </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>
                                        <p className="fs24 text-center">
                                            <span className="fs16" style={{ color: '#86835d' }}>
                                            “And of His signs is that He created for you from yourselves mates that you may
                                            find tranquility in them; and He placed between you affection and mercy. Indeed
                  in that are signs for a people who give thought.” <br /><br />(Ar-Rum: 21)
                                                 </span>
                                        </p>
                                    </Item>
                                    <Container id='sectiongold57'>
                                        <div className='py-3'>

                                            <Item>
                                               
                                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                                className='col-10 col-lg-8 kotak' style={{ backgroundColor: csub }}>


                                                                <Item>
                                                                    <div className='item'>
                                                                        <Item>
                                                                            <div>
                                                                                {days}
                                                                            </div>
                                                                        </Item>
                                                                        <Item>
                                                                            <span>
                                                                                Days
                                    </span>
                                                                        </Item>
                                                                    </div>
                                                                    <div className='dot'>:</div>
                                                                    <div className='item'>
                                                                        <Item>
                                                                            <div>
                                                                                {hours}
                                                                            </div>
                                                                        </Item>
                                                                        <Item>
                                                                            <span>
                                                                                Hours
                          </span>
                                                                        </Item>
                                                                    </div>
                                                                    <div className='dot'>:</div>
                                                                    <div className='item'>
                                                                        <Item>
                                                                            <div >
                                                                                {minutes}
                                                                            </div>
                                                                        </Item>
                                                                        <Item>
                                                                            <span>
                                                                                Mins
                          </span>
                                                                        </Item>
                                                                    </div>
                                                                    <div className='dot' >:</div>
                                                                    <div className='item'>
                                                                        <Item>
                                                                            <div>
                                                                                {seconds}
                                                                            </div>
                                                                        </Item>
                                                                        <Item>
                                                                            <span>
                                                                                Secs
                          </span>
                                                                        </Item>
                                                                    </div>
                                                                </Item>


                                                            </div>
                                                  
                                            </Item>
                                        </div>
                                    </Container>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: '#86835d' }}>
                                            The Intimate Wedding of {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >
                                    <Item>
                                    <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' >
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={pw(id,"ce.jpg")} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{ fontSize: '36px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                                                        <b>The Daughter of : </b><br />
                                                        {bapak_ce}
                                                        <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0' style={{ width: '35px', height: '35px' }}
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} />
                                                </Item>
                                            </div>
                                        </div>
                                    
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' >
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={pw(id,"co.jpg")} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{ fontSize: '36px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                                                        <b>The Son of : </b><br />
                                                        {bapak_co}
                                                        <br />
                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0' style={{ width: '35px', height: '35px' }}
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} />
                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: '#86835d' }} >
                                    <Item>
                                        <p className="fs16">
                                            The wedding will be held on :
                    </p>
                                    </Item>
                                   
                                    <Item>
                                        
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid #86835d` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: '#86835d' }}>
                                            <b className="allura" style={{ fontSize: '46px' }}> Intimate Wedding</b><br />
                                           
                                            <div className="py-3">
                                            Sunday, March 7th 2021
                                            <br/>
                                            11.00-14.00 WIB
                                             </div>
                                            <br />

                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="w-100 text-center">
                                            <span style={{fontSize:'20px'}}>
                                                <b>
                                                ADELLE SKY LOUNGE THE SAHIRA HOTEL - 8th Floor 
                    </b> <br />
                    
                    Jl. Ahmad Yani No. 17-23, Kota Bogor, Jawa Barat 16161
                    </span>
                                        </p>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                      style={{
                        border: `2px solid #86835d`,
                        borderRadius: '10px'
                      }}
                      type="button"
                      onClick={() => {
                        window.open("https://goo.gl/maps/T56KKUEFdacmA8Rj8")
                      }}
                      className="p-2 mx-sm-2">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                          style={{ width: "10%", height: 'auto' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: '#86835d' }}>
                          <b>Get Direction</b>
                        </p>
                      </Item>
                    </Col>
                                        <Col
                                            onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=MnZka281c3A4aDdlaHM4cG4zdWlrMTc0YjUgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid #86835d`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn ">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: 'auto' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: '#86835d' }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>

                                
                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Item>
                                        <div className="butterfly">
                                            <h1 style={{ color: '#86835d', fontStyle: 'bold', fontWeight: 700, fontSize: '72px' }}>
                                                The Couple
                        </h1>
                                        </div>
                                    </Item>
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4' style={{ color: '#86835d' }}>
                                                        The paths of pleasure and sadness are studded. Whether it's a misinterpretation or not. Your presence completes the pleasures of life. <br/>
                                                        #poemrc

                              </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>
                                
                                
                                <Container fluid
                                    className={`text-center mt-3 aulia-wedding py-3 px-3 trs`} >

                                    <Col className="py-3 px-md-5 mx-2 w-100 text-center" style={{ backgroundColor: cmain, borderRadius: '10px', color: '#86835d' }}>
                                        <h1 style={{ color: '#86835d' }} className="w-100 text-center">
                                            Wedding Gift
                      </h1>
                                        <p className="text-center w-100" style={{ color: '#86835d' }}>
                                         BCA-7390951099  <br />
                            (Shinta Rizqi Amalia)
                          </p>
                                        <Item>
                                            <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                                                <Toast.Body>Copied!</Toast.Body>
                                            </Toast>
                                        </Item>
                                        <Item>
                                            <CopyToClipboard text="7390951099">
                                                <Col xs={8} className='btn button mb-5 mt-1'
                                                    onClick={(e) => {
                                                        this.setState({ show: true })
                                                    }}>
                                                    Salin No. Rekening
                          </Col>
                                            </CopyToClipboard>
                                        </Item>
                                        <p className="text-center w-100" style={{ color: '#86835d' }}>
                                        BNI-0326692752  <br />
                            (Ridwan Ramadhan)
                          </p>
                                        <Item>
                                            <Toast onClose={() => this.setState({ show1: false })} show={this.state.show1} delay={3000} autohide >
                                                <Toast.Body>Copied!</Toast.Body>
                                            </Toast>
                                        </Item>

                                        <Item>
                                            <CopyToClipboard text="0326692752">
                                                <Col xs={8} className='btn button mb-5 mt-1'

                                                    onClick={(e) => {
                                                        this.setState({ show1: true })
                                                    }}>
                                                    Salin No. Rekening
                          </Col>
                                            </CopyToClipboard>
                                        </Item>
                                       
                                        
                                        
                                    </Col>


                                   
                                </Container>
                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-12 col-lg-10 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: '#86835d'
                                                    }}>
                                                        Send Your Wishes
                                                     </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={12} md={6}>
                                                        <form className="col-12 w-100">
                                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                           
                                                            <Item>
                                                                <Col xs={12} className=''>
                                                                    {
                                                                        submitted == true ? (
                                                                            <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                Pesan anda sudah disampaikan
                                                                            </Alert>) : (submitted === false ? (
                                                                                <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                    {
                                                                                        err.map(val => {
                                                                                            return (
                                                                                                <li>{val}</li>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </Alert>
                                                                            ) : false)
                                                                    }

                                                                </Col>
                                                            </Item>
                                                            <Item>
                                                                <div className='col-6 button rounded btn'
                                                                    onClick={() => this.handleSubmit()} style={{ backgroundColor: '#86835d', color: 'white' }} no> Send </div>
                                                            </Item>
                                                        </form>

                                                    </Col>
                                                    {/* <Col xs={12} md={6}>
                                                        <div className='col-12 kotak pb-4 pt-4 mt-3 mt-md-0' data-aos="left-left" data-aos-duration="1000" style={{ height: '500px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                            {comment ? comment.map(v => {
                                                                return (
                                                                    <Item>
                                                                        <Col xs={2}>
                                                                            <img src='https://www.flaticon.com/svg/static/icons/svg/3447/3447670.svg' className="img-fluid w-100" />
                                                                        </Col>
                                                                        <Col xs={10}>
                                                                            <Alert variant="secondary col-12">
                                                                                <p style={{ fontSize: '16px' }} className='m-0'>
                                                                                    {decodeURI(v.pesan)}
                                                                                    <br/>
                                                                                    <span style={{fontStyle:'italic'}}>
                                                                                  <b>   
                                                                                  {decodeURI(v.dari)}
                                                                                  </b>
                                                                                    </span>
                                                                                </p>
                                                                            </Alert>
                                                                        </Col>
                                                                    </Item>
                                                                )
                                                            }) : false

                                                            }
                                                        </div>
                                                    </Col>
                                                 */}
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container className="text-center dinny py-3">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid #86835d`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: '#86835d'
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className=" text-center w-100 fs16" style={{color:'#86835d'}}>
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={3}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className=" text-center w-100 fs16" style={{color:'#86835d'}}>
                              Gunakan Masker
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={3}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className=" text-center w-100 fs16" style={{color:'#86835d'}}>
                              Jaga Jarak
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={3}>
                          <img src={pw("asset","suhu.svg")} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16" style={{color:'#86835d'}}>
                            {'Suhu Tubuh < 37°C'}
                      </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={3}>
                        <img src={cuci} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                            Mencuci Tangan 
                          </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container>
                


                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

